import type { AccessType, ContentTypes } from '@/constants/postInfo'
import type { PossiblyNull } from './common'

export type APIResponse<T> = {
  data: T
  errors: { http_status: number; code: number; message: string }[]
}

// prettier-ignore
export type ContentType = (typeof ContentTypes)[number]

export type StatsType = {
  views: number
  likes: number
  totalComments: number
}

export type PublicTagType = {
  text: string
  slug: string
}

export type PublicGroupMembership = {
  slug: string
  name: string
}

type SocialMediaType = { twitterUrl?: string; linkedInUrl?: string }

export type PublicProfileType = {
  id: string
  title: string // i.e: IT Manager
  industry: string // i.e: IT Services
  companySize: string // i.e: 501 - 1,000 employees
  companyName: string
  isAnonymous: boolean
  contributionPoints: number
  pulsePoints: number
  pic: string
  displayname: string
  email: string
  slug: string
  country: string
  isAmbassador: boolean
  isVerified: boolean
  isUserBlocked: boolean
  firstname: string
  socialMedia?: SocialMediaType
  departments: number[]
  isReadOnly: boolean
  hasDemoAccess: boolean
  connectionsCount: number
  firstSeen: string
  isCustomer: boolean
  level: number | undefined
  primaryFunction: number | undefined
  additionalGpiFunctions: number[]
  groupsMembership: PublicGroupMembership[]
  hasPeerFinderAccess: boolean
  isPointsRequired: boolean
  accessFormRequired: boolean
  useSplitAccessForm: boolean
  consumptionAccessFormRequired: boolean
  contributionAccessFormRequired: boolean
  publicStats: ProfileStatsType
}

export type MinimalProfileType = Pick<
  ProfileType,
  'displayname' | 'slug' | 'pic' | 'titledisplay' | 'company_name' | 'is_ambassador' | 'verification_state'
> & {
  profile_id: ProfileType['_id']
  departments: ProfileType['departments']['ids']
}

export type PublicMinimalProfileType = Pick<ProfileType, 'slug' | 'pic'> & {
  id: ProfileType['_id']
  displayName: ProfileType['displayname']
  title: ProfileType['titledisplay']
  companyName: ProfileType['company_name']
  departments: ProfileType['departments']['ids']
  isVerified: boolean
  isAmbassador: boolean
}

export type ChartDataItemType = {
  label: string
  data: number | string | number[]
}

export type ChartDataType = {
  groups: ChartDataItemType[]
  barlabels: string[]
  title: string
  numresponses: number
}

export type ChartDataItemWithWinnerType = ChartDataItemType & { winner: boolean }

export type StringOrNumberType = string | number | number[]

export type ChartDatasetItem = {
  label: string
  data: StringOrNumberType[]
  fill?: boolean
  borderColor?: string
  tension?: number
}

export type ChartDataSource = {
  datasets: ChartDatasetItem[]
  labels: string[]
}

export type PublicPollDataSummaryType = {
  participants: number
  results: ChartDataItemWithWinnerType[]
  dataSource: ChartDataSource
  shouldChartRender: boolean
}

export type PathType = {
  type: 'post' | 'poll'
  slug: string
}

export type FeedParams = {
  prev_ids?: string[]
}

export interface PostType {
  _id: string
  access_type: AccessType
  anonymous_author: ProfileType
  anonymous: boolean
  authorid: string
  body: string
  channel_slug: string
  chartdata?: ChartDataType[]
  comments: CommentType[] | null
  conference_code: string
  departments: number[] | null
  featured_profiles: string[]
  hide_activity: boolean
  intro: string
  last_activity: string[]
  last_updated: string
  levels: number[]
  locked: boolean
  num_comments: number
  num_responses: number
  options?: string[]
  parent_id: string
  parent_slug: string
  parent_type: number
  parent: null
  product_question_type: number
  reward: RewardType
  short_text: string
  slug: string
  source: string
  state: number
  tag_ids_v2: string[] | null
  tag_ids: string[]
  target_audience: TargetAudience
  test: boolean
  text: string
  title: string
  type: number
  upvoters: Upvoter[]
  views: number
  is_saved: boolean
  is_readonly: boolean
}

export interface Account {
  anonymousaccount: { tokens: string[] }
  apiaccount: object
  emailaccount: {
    code_expiry: string
    code: string
    new_user: boolean
    session_counter: number
    tokens: string[]
  }
  facebookaccount: { psid: string }
  gartneraccount: {
    first_auth_date: string
    user_id: string
    user_type: string
  }
  linkedinaccount: { id: string; token: string }
  linkedinmanualaccount: { email: string }
  messengeraccount: { psid: string }
  type: string
}

export interface Metadata {
  company_name: string
  company_size: number
  country: string
  departments: number[]
  gpifunctions: number[]
  gpiindustries: number[]
  industries: number[]
  level: number
  title_override: string
  titledisplay: string
}

export interface ProfileType {
  _id: string
  firstname: string
  lastname: string
  displayname: string
  email: string
  other_emails: string[]
  phone: string
  last_updated: string
  first_seen: string
  last_seen: string
  last_contacted: string
  unread_data: UnreadData
  slug: string
  pic: string
  pic_font: string
  locale: string
  timezone: number
  claimed_profile_id: string
  skills: SkillDTO[] | null
  badge_status: number
  industries: number[]
  department: number
  departments: Departments
  company_size: number
  company_name: string
  level: number | undefined
  titledisplay: string
  title_override: string
  pulsepoints: number
  contribution_points: number
  numresponses: number
  numcomments: number
  accounts: Account[]
  onboarding_status: null
  fre_status: null
  onboarding: boolean
  long: string
  lat: string
  country: string
  city: string
  state: string
  channels: ChannelType[]
  groups_membership: null
  sources: null
  premiumness: number
  views: Views
  gifts: null
  device_push_tokens: null
  reports_unlocked: boolean
  customer: boolean
  loggedin_pcc: boolean
  metadata: Metadata[]
  today_customer: TodayCustomer
  today: Today
  social_media?: SocialMedia
  work_email: string
  accepted_terms_and_condition_id: string
  last_terms_and_conditions_accepted_on: string
  accepted_privacy_policy_id: string
  last_privacy_policy_accepted_on: string
  accepted_unified_terms_id: string
  unified_terms_accepted_on: string
  verification_state: number
  verification_owner: string
  verification_state_reason: string
  is_publicly_visible: boolean
  is_public_visibility_set_by_user: boolean
  last_pi_sync_time: string
  is_post_pi_integration: boolean
  gartner_client_election_made: boolean
  pc_attributes: PCAttributes
  upgrade_experience_election_made: boolean
  gpifunctions: number[] | null
  gpiindustries: number[] | null
  company_revenue_size: number
  is_ambassador: boolean
  primary_gpi_function: number | undefined
  primary_gpi_industry: number
  additional_gpi_functions: number[] | null
  additional_gpi_industries: number[] | null
  connections_count: number
  stats?: StatsDTO
  /**
   * @deprecated
   */
  peer_finder_access_allowed: boolean
}

interface ChannelAssetsType {
  icon_url: string
  bg_image: string
  terms_url: string
  pp_url: string
}

interface ChannelSettingsType {
  text_color: string
}

interface ChannelCompanyType {
  name: string
  url: string
}

interface ChannelCommunityManagerType {
  name: string
  email_from: string
  reply_to: string
}

export interface GroupMembershipChannelType {
  create_date: string
  slug: string
}

export interface GroupMembershipType {
  create_date: string
  slug: string
  channels: GroupMembershipChannelType[]
  state: {
    start: string
    status: number
    period: number
    expires: string
  }
}

export interface ChannelType {
  _id: string
  name: string
  slug: string
  admin_id: string
  member_count: number
  assets: ChannelAssetsType
  settings: ChannelSettingsType
  company: ChannelCompanyType
  community_manager: ChannelCommunityManagerType
  plan: string
  description: string
}

export interface Departments {
  ids: number[] | null
  last_updated: string
}

export interface CommentType {
  createdate: string
  profileid: string
  anonymous_author: null
  anonymous: boolean
  text: string
  upvoters: Upvoter[]
  replies: CommentType[] | null
}

interface RewardType {
  threshold: number
  threshold_amount: number
  threshold_met: boolean
  amount_min: number
  amount_max: number
  amount_multiplier: number
  amount_display: string
}

interface PCAttributes {
  peer_connect_id: number
  peer_connect_username: string
  migrated_date: string
  is_new: boolean
}

interface SocialMedia {
  linkedin_url: string
  twitter_url: string
}

interface Today {
  fre_shown: boolean
  last_seen: string
  feed: Feed
}

interface Feed {
  content: null
  whitepapers: null
}

interface TodayCustomer {
  last_seen: string
  feed: Feed
}

interface UnreadData {
  badges: null
  push_date: string
  queried_date: string
  marked_as_read: null
}

interface Views {
  count: number
  last_count_notified: number
}

export interface Upvoter {
  profile_id: string
  create_date: string
}

interface TargetAudience {
  company_sizes: number[]
  industries: number[]
  titles: Title[] | null
  geography: string[] | null
}

interface Title {
  department: number
  levels: number[]
}

export enum UpvoteTypes {
  POST = 'POST',
  COMMENT = 'COMMENT',
  REPLY = 'REPLY',
}

export interface PublicItemType {
  postType: PostType['type']
  postId: PostType['_id']
  entityType: keyof typeof UpvoteTypes
  commentIndex?: number
  replyIndex?: number
  isSaved?: boolean
  isReadOnly?: boolean
}

export interface WithEntityTypeAndUpvoters extends PublicItemType {
  upvoters: Upvoter[]
  text: string
  stats: StatsType
}

export type PublicReplyType = WithEntityTypeAndUpvoters &
  Pick<CommentType, 'createdate'> &
  Pick<StatsType, 'likes'> & {
    profile: PublicProfileType
    fromNow: string
    commentIndex: number
    replyIndex: number
  } & {
    mentions: Mention[]
  }

export type PublicCommentType = Omit<PublicReplyType, 'replyIndex'> & {
  replies: PublicReplyType[]
}

export type MentionKind = 'market' | 'product' | 'vendor'

export type Mention = {
  kind: MentionKind
  url: string
  authorProfileId: string
  createdTime: string
  length: number
  location: MentionLocation
  marketName: string
  marketSeoName: string
  startIndex: number
  targetText: string
  // Vendor
  logoUrl?: string
  vendorName?: string
  vendorSeoName?: string
  // Product
  productName?: string
  productSeoName?: string
}

export const isVendor = (mentionedObject: MentionedObject) => 'vendor_name' in mentionedObject
export const isProduct = (mentionedObject: MentionedObject) => 'product_name' in mentionedObject

export type PublicPostType = WithEntityTypeAndUpvoters & {
  contentType: ContentType
  stats: StatsType
  comments: PublicCommentType[]
  tags: PublicTagType[]
  path: string
  pollSummary?: PublicPollDataSummaryType
  fromNow: string
  created: number
  isSeoIndexable: boolean
  isSurvey: boolean
  accessType: AccessType
  channelName?: string
  // channel and channelName seem to be handled separately in the SPA logic for the allowed contributors message
  channel?: ChannelType
  mentions: Mention[]
} & Pick<PostType, 'slug' | '_id' | 'type' | 'options' | 'chartdata' | 'source' | 'num_responses' | 'departments'>

// Stats
export interface StatsDTO {
  written_answers_count: number
  profile_viewed_count: number
  upvotes_received_count: number
}

export interface ProfileStatsType {
  answers: number
  views: number
  upvotes: number
}

// Awards

export interface AwardDTO {
  tag_id: string
  tag_name: string
  month: number
  year: number
}

export interface ProfileAwardType {
  id: string
  name: string
  count: number
}

export interface ProfileAwardCount {
  total: number
  items: ProfileAwardType[]
}

export interface TopAnswerType {
  content: PostType
}
export interface PublicProfilePageType extends PublicProfileType {
  awards: ProfileAwardCount
  skills: ProfileSkillType[]
  feed: PublicPostType[]
  connectionState: ConnectionStates
  topAnswers: PublicPostType[]
  hasContributions: boolean
  hasSkills: boolean
  hasAwards: boolean
}
export type PartialPublicProfilePageType = Pick<
  PublicProfilePageType,
  | 'id'
  | 'pic'
  | 'displayname'
  | 'socialMedia'
  | 'title'
  | 'country'
  | 'isAmbassador'
  | 'connectionState'
  | 'isVerified'
  | 'isUserBlocked'
  | 'connectionsCount'
>
// Skills

export interface SkillDTO {
  tag_id: string
  interest: SkillLevelDTO
  expertise: SkillLevelDTO
}

interface SkillLevelDTO {
  declared: boolean
  level: number
}

export interface ProfileSkillType {
  id: string
  name: string
  level: number
  slug: string
  selfDeclared: boolean
}

export interface ConnectionType {
  outgoing_id: string
  incoming_id: string
  state: number
  last_updated: string
  message: string
  connection_type: number
  target_metadata: TargetMetadata
}

interface TargetMetadata {
  email: string
  first_name: string
  full_name: string
  gartner_id: string
}

export enum ConnectionStates {
  NOT_CONNECTED = 999_999,
  REQUESTED = 1,
  CONNECTED = 5,
}

export enum ConnectionTypes {
  DEFAULT_CONNECTION = 0,
  GPC_CONNECTION = 1,
  PEER_FINDER_CONNECTION = 2,
}

interface CommunityAccessParamsType {
  status: AccessStatus
  use_split_access_form: boolean
  access_form_required: boolean
  consumption_access_form_required: boolean
  contribution_access_form_required: boolean
  primary_asset: string
  block_cta: string
  block_cta_link: string
  block_title: string
  block_message: string
  peer_finder_net_conn_req_status: AccessStatus
  policy_prompt_required: boolean
  gartner_client_greeting_required: boolean
  prompt_upgraded_experience_required: boolean
  is_points_required: boolean
  is_associate_read_only: boolean
  gpc_demo_page_access_status: AccessStatus
}

export enum AccessStatus {
  ALLOWED = 'ALLOWED',
  BLOCKED = 'BLOCKED',
}

export interface TopicType {
  _id: string
  name: string
  description: string
  type: number
  slug: string
  image_url: string
  parent_id: string
  version: number
  following: false
}

export interface PublicTopicType {
  id: string
  name: string
  description: string
  slug: string
  parentId: string
}

export interface NotificationsType {
  unread_message_count: number
  new_message_count: number
  last_open_time: string
}

// Mentions

export type MentionLocation = 'root' | 'root-body' | 'comment' | 'reply'

interface BaseMention {
  author_profile_id: string
  created_time: string
  length: number
  location: MentionLocation
  start_index: number
  target_text: string
}

export interface MarketMention extends BaseMention {
  market_name: string
  market_seo_name: string
}

export interface VendorMention extends MarketMention {
  logo_url: string
  vendor_name: string
  vendor_seo_name: string
}

export interface ProductMention extends VendorMention {
  product_name: string
  product_seo_name: string
}

export type MentionedObject = BaseMention & MarketMention & Partial<VendorMention> & Partial<ProductMention>

export interface MentionedObjects {
  vendors: PossiblyNull<VendorMention[]>
  products: PossiblyNull<ProductMention[]>
  markets: PossiblyNull<MarketMention[]>
}

// Responses

export interface GetPostResponse {
  can_delete: boolean
  channel: ChannelType
  constructedInsights: {
    plaintext: string
    contrast: number
    option_index: number
  }[]
  contributor_level_copy: string
  is_readonly: boolean
  is_saved: boolean
  mentioned_objects: MentionedObjects
  post: PostType
  profiles: ProfileType[]
  url: string
}

export interface GetProfileResponse {
  profile: ProfileType
  community_access_params: CommunityAccessParamsType
  connections_count: number
  is_points_required: boolean
}

// Sitemaps

export interface IndexableContent {
  slug: string
  last_updated: string
}

export interface Pagination {
  total: number
  page: number
  page_size: number
  total_pages: number
}

export interface IndexableContentResponse {
  entries: IndexableContent[]
  page: Pagination
}

export type IndexableContentType = 'post' | 'poll'
