export const removeEntriesByKey = <T>(entries: IterableIterator<[string, T]>, keys: string[]) => {
  const result: { [x: string]: T } = {}
  for (const [key, value] of entries) {
    if (!keys.includes(key)) {
      result[key] = value
    }
  }
  return result
}
type RangeFn = (begin: number, end: number, step?: number) => number[]
export const range: RangeFn = (begin, end, step = 1) => {
  if (begin > end) {
    const absStep = Math.abs(step)
    return range(end, begin, Math.abs(absStep)).reverse()
  }
  end = Math.floor(end / step)
  const len = end - begin + 1
  return Array(len)
    .fill(0)
    .map((_, i) => i * step + begin)
}

export const isWithinRange = (candidate: number, reference: number, offset: number) =>
  reference - offset <= candidate && candidate <= reference + offset

export const getCroppedRange = (rangeStart: number, rangeEnd: number, current: number, offset: number) =>
  range(rangeStart, rangeEnd).filter((it) => it === rangeStart || it === rangeEnd || isWithinRange(it, current, offset))

export const paginate = (current: number, last: number, delta = 2) => {
  if (last === 1) {
    return [1]
  }

  const left = current - delta
  const right = current + delta + 1
  const range: (number | null)[] = []

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      if (i === left && i > 2) {
        range.push(null)
      }
      range.push(i)
      if (i === right - 1 && i < last - 1) {
        range.push(null)
      }
    }
  }

  return range
}

/**
 * Normalizes an array of objects into an object where the keys are extracted from a
 * specific property of each object, and the values are the objects themselves.
 *
 * @template T - The type of the objects in the array.
 * @template K - The type of the key used for normalization, which extends keyof T.
 * @param {T[]} items - The array of objects to normalize.
 * @param {K} key - The key in each object used to determine the unique property for normalization.
 *                  This key must exist in the type `T` (constraint `K extends keyof T`).
 * @returns {Record<string, T>} - A normalized object where the keys are
 *                                the stringified values of the specified property,
 *                                and the values are the objects themselves.
 *
 * @remarks
 * - If the value of `item[key]` is `null` or `undefined`, that object is skipped in the result.
 * - If multiple objects share the same value for `key`, the last object in the array with that value will overwrite previous ones.
 * - The key parameter must exist on the type `T` (i.e., `K extends keyof T`).
 *
 * @example
 *
 * interface User {
 *   id: number;
 *   name: string;
 * }
 *
 * const users: User[] = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 3, name: 'Charlie' }
 * ];
 *
 * const normalizedUsers = normalize(users, 'id');
 * console.log(normalizedUsers);
 * // Output:
 * // {
 * //   "1": { id: 1, name: 'Alice' },
 * //   "2": { id: 2, name: 'Bob' },
 * //   "3": { id: 3, name: 'Charlie' }
 * // }
 */
export const normalize = <T, K extends keyof T>(items: T[], key: K): Record<string, T> =>
  items.reduce<Record<string, T>>((acc, item) => {
    const keyValue = item[key]
    if (keyValue != null) {
      acc[String(keyValue)] = item
    }
    return acc
  }, {})

/**
 * Adds a property to an object if the value is not `undefined`.
 *
 * @template T - The type of the object.
 * @template K - The key of the object to be added.
 * @param {T} obj - The original object.
 * @param {K} key - The key of the property to be added.
 * @param {T[K] | undefined} value - The value of the property to be added. If `undefined`, the object is returned unchanged.
 * @returns - The new object with the added property if the value exists, or the original object if the value is `undefined`.
 *
 * @example
 * const result = addPropertyIfExists({ a: 1 }, 'b', 2);
 * console.log(result); // { a: 1, b: 2 }
 *
 * @example
 * const result = addPropertyIfExists({ a: 1 }, 'b', undefined);
 * console.log(result); // { a: 1 }
 */
export const addPropertyIfExists = <T, K extends keyof T>(obj: T, key: K, value: T[K] | undefined) => {
  if (value !== undefined) {
    return { ...obj, [key]: value }
  }
  return obj
}
