import { GPC_DEPARTMENTS } from '@/constants/gpcDepartments'
import { GPI_FUNCTIONS } from '@/constants/gpiFunctions'
import type { PublicMinimalProfileType, PublicProfileType } from '@/types/api'

export const isProfileReadOnly = (profile: PublicProfileType) => profile.isReadOnly

export const filterPublicMinimalProfilesByDepartments = (
  profiles: PublicMinimalProfileType[],
  departments: PublicMinimalProfileType['departments']
) =>
  departments === null || departments.length < 1
    ? profiles
    : profiles.filter((it) => it.departments?.some((dp) => departments.includes(dp)))

type WithDeparments = Pick<PublicMinimalProfileType, 'departments'>
export const getDepartmentsFromProfiles = (profiles: WithDeparments[]) => {
  const departmentIds = new Set<number>()
  const departmentsWithNames = []
  for (const profile of profiles) {
    for (const id of profile.departments || []) {
      if (!departmentIds.has(id)) {
        departmentIds.add(id)
        departmentsWithNames.push({
          id: id.toString(),
          name: { ...GPC_DEPARTMENTS, ...GPI_FUNCTIONS }[id].name,
        })
      }
    }
  }
  return departmentsWithNames.sort((a, b) => a.name.localeCompare(b.name))
}
