type Department = {
  id?: number
  name: string
  slug: string
  topTitle?: string
  jobTitles?: string[]
}

export const GPC_DEPARTMENTS: Record<string, Department> = {
  '10': { name: 'CEO', slug: 'ceo' },
  '11': { name: 'IT', slug: 'it' },
  '12': { name: 'Marketing', slug: 'marketing' },
  '13': { name: 'Software Engineering', slug: 'engineering' },
  '14': { name: 'Sales', slug: 'sales' },
  '15': { name: 'Finance', slug: 'finance' },
  '16': { name: 'Product Management', slug: 'product-management' },
  '17': { name: 'Design', slug: 'designer' },
  '18': { name: 'Operations', slug: 'operations' },
  '19': { name: 'Business Development', slug: 'business-development' },
  '20': { name: 'Communications', slug: 'communications' },
  '21': { name: 'Customer Success', slug: 'customer-success' },
  '22': { name: 'Customer Support', slug: 'customer-support' },
  '23': { name: 'HR', slug: 'hr' },
  '24': { name: 'Data & Analytics', slug: 'data-operations' },
  '25': { name: 'Project Management', slug: 'pmo' },
  '26': { name: 'Corporate Development', slug: 'corp-dev' },
  '27': { name: 'Legal', slug: 'legal' },
  '28': { name: 'Information Security', slug: 'information-security' },
  '29': { name: 'Supply Chain', slug: 'supply-chain' },
  '30': { name: 'Other', slug: 'other' },
}
