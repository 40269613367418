import { FeedTypes } from '@/constants/gpc'
import type { ContentType } from '@/types/api'

export const POST = 'POST'
export const POLL = 'POLL'
export const DISCUSSION = 'DISCUSSION'
export const REPORT = 'REPORT'
export const CLOSED = 'CLOSED'
export const NO_LABEL = 'NO_LABEL'
export const COMMENT = 'COMMENT'
export const REPLY = 'REPLY'

export const ContentTypes = [POLL, DISCUSSION, REPORT, CLOSED, NO_LABEL] as const

export enum AccessType {
  CLIENT = 0,
  ALL = 1,
}

type TypeLabelsType = {
  [x: number]: ContentType
}

export const TypeLabels: TypeLabelsType = {
  [FeedTypes.LongForm]: DISCUSSION,
  [FeedTypes.MultiSelect]: POLL,
  [FeedTypes.Poll]: POLL,
  [FeedTypes.Rank]: POLL,
  [FeedTypes.RatingScale]: POLL,
  [FeedTypes.AssessmentReport]: REPORT,
  [FeedTypes.ProductReport]: REPORT,
  [FeedTypes.Report]: REPORT,
} as const

const PathPrefixTypes = ['/poll', '/post', '/topic'] as const

// prettier-ignore
type PathPrefixType = (typeof PathPrefixTypes)[number]

type TypePathPrefixByLabelType = {
  [x: string]: PathPrefixType
}
export const TypePathPrefixByLabel: TypePathPrefixByLabelType = {
  [POLL]: '/poll',
  [DISCUSSION]: '/post',
  [REPORT]: '/topic',
} as const

export const CompanySizes: { [x: number]: string } = {
  20: '2 - 10',
  30: '11 - 50',
  40: '51 - 200',
  50: '201 - 500',
  60: '501 - 1,000',
  70: '1,001 - 5,000',
  80: '5,001 - 10,000',
  90: '10,001+',
} as const

export const Industries: { [x: number]: string } = {
  1000: 'Travel and Hospitality',
  1001: 'Media',
  1002: 'Healthcare and Biotech',
  1003: 'Construction',
  1004: 'Consumer Goods',
  1005: 'Education',
  1006: 'Banking',
  1007: 'Finance (non-banking)',
  1008: 'Insurance (except health)',
  1009: 'Hardware',
  1010: 'Manufacturing',
  1011: 'Energy and Utilities',
  1012: 'Services (non-Government)',
  1013: 'Government',
  1014: 'Real Estate',
  1015: 'Retail',
  1016: 'Software',
  1017: 'Telecommunication',
  1018: 'Transportation',
  1019: 'Miscellaneous',
  1020: 'IT Services',
  10: 'Mining, Quarrying, and Oil and Gas Extraction',
  15: 'Manufacturing',
  20: 'Construction',
  25: 'Educational Services',
  30: 'Consumer Goods',
  35: 'Health Care and Social Assistance',
  40: 'Finance and Insurance',
  45: 'Software',
  50: 'Telecommunication Services',
  55: 'Utilities',
  60: 'Real Estate, Rental and Leasing',
  65: 'Accommodation and Food Services',
  70: 'Arts, Entertainment and Recreation',
  75: 'Agriculture, Forestry, Fishing and Hunting',
  80: 'Professional Services',
  85: 'Transportation and Warehousing',
  90: 'Retail',
  95: 'Government',
  100: 'Other Services (except Public Administration)',
  105: 'Hardware',
  110: 'Biotech and Scientific R&D',
} as const
