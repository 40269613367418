import type React from 'react'
import type { DetailedHTMLProps, HTMLAttributes, TextareaHTMLAttributes } from 'react'
import type { PublicCommentType } from './api'

export type SpanProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
export type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
export type FormProps = DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>
export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
export type SectionProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
export type TextareaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

export type EventWithData = Element & {
  dataset?: {
    evna: string
    evco: string
    evob: string
    evpk: string
    evpla: string
  }
}
export type GAEvent = {
  name?: string
  component?: string
  object?: string
  pk?: string
  platform?: string
}

export enum Size {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum CommentSortBy {
  oldest = 'oldest',
  newest = 'newest',
  mostUpvoted = 'mostUpvoted',
  mostReplied = 'mostReplied',
}

export type PublicCommentSortFn = (a: PublicCommentType, b: PublicCommentType) => number

export type PostConfigType = {
  type: 'post' | 'poll'
  slug: string
}

export type ServerActionResponse<T> = {
  data?: T
  error?: { status: number; message?: string }
}

// https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/
export type AsProp<T extends React.ElementType> = { as?: T }
export type PropsToOmit<T extends React.ElementType, P> = keyof (AsProp<T> & P)
export type PolymorphicComponentProp<T extends React.ElementType, Props = {}> = Omit<
  React.ComponentPropsWithoutRef<T>,
  PropsToOmit<T, Props>
> &
  React.PropsWithChildren<Props & AsProp<T>>
export type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>['ref']
export type PolymorphicComponentPropWithRef<T extends React.ElementType, Props = {}> = PolymorphicComponentProp<
  T,
  Props
> & { ref?: PolymorphicRef<T> }

export type PossiblyNull<T> = T | null
