import { channelNames } from '@/constants/channels'
import { MAX_POLL_DATA } from '@/constants/gpc'
import type { PartialProfileType } from '@/constants/profile'
import { TagNames, TagSlugs } from '@/constants/tagInfo'
import {
  type AwardDTO,
  type ChannelType,
  type ChartDataType,
  type ChartDatasetItem,
  type CommentType,
  type GroupMembershipType,
  type Mention,
  type MentionKind,
  type MentionedObject,
  type MentionedObjects,
  type PostType,
  type ProfileAwardCount,
  type ProfileAwardType,
  type ProfileSkillType,
  type ProfileStatsType,
  type ProfileType,
  type PublicCommentType,
  type PublicGroupMembership,
  type PublicPollDataSummaryType,
  type PublicPostType,
  type PublicProfileType,
  type PublicReplyType,
  type SkillDTO,
  type StatsDTO,
  type StringOrNumberType,
  UpvoteTypes,
  isProduct,
  isVendor,
} from '@/types/api'
import { CommentSortBy, type PublicCommentSortFn } from '@/types/common'
import { addPropertyIfExists } from '@/util/iterators'
import {
  countCommentsAndReplies,
  getCompanySizeLabel,
  getIndustryLabel,
  getLabel,
  getPostPath,
  getTagName,
  getTagSlug,
  isReportType,
} from '@/util/post'
import { isSeoPage } from '@/util/seo'
import dayjs, { type ConfigType } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { UserStatus } from '../profile/constants'
dayjs.extend(relativeTime)

const EmptyProfile: PartialProfileType = {
  _id: '',
  titledisplay: 'no title',
  company_size: 1,
  gpiindustries: [0],
  contribution_points: 0,
  pulsepoints: 0,
  pic: '',
  displayname: '',
  email: '',
  slug: '',
  country: '',
  is_ambassador: false,
  firstname: '',
  company_name: '',
  title_override: '',
  departments: {
    ids: [],
    last_updated: '',
  },
  verification_state: 0,
  first_seen: '',
  customer: false,
  level: undefined,
  primary_gpi_function: undefined,
  additional_gpi_functions: [],
  groups_membership: null,
  channels: [],
  connections_count: 0,
}

const mapProfiles = (profiles: ProfileType[]): Map<string, ProfileType> => {
  const profileMap = new Map<string, ProfileType>()
  profiles.forEach((x) => profileMap.set(x._id, x))
  return profileMap
}
type OptionSum = { [key: string]: number }

const getChartLabels = (chartdata: ChartDataType[] = []): string[] =>
  chartdata.map((cd) => cd.title.replace('202', "'2")).slice(MAX_POLL_DATA * -1)

const getChartDataSet = (chartdata: ChartDataType[], options: string[]): ChartDatasetItem[] => {
  const dict: { [x: string]: StringOrNumberType[] } = {}
  chartdata.forEach((data) => {
    const percentageGroup = data.groups
    percentageGroup.forEach(({ label, data: value }) => {
      dict[label] = dict[label] || []
      dict[label].push(value)
    })
  })
  const dataset = options.map((opt) => ({
    label: opt,
    data: (dict[opt] || []).slice(MAX_POLL_DATA * -1),
  }))
  return dataset
}

const transformToPublicPollDataSummary = ({
  options = [],
  chartdata = [],
}: Partial<PostType>): PublicPollDataSummaryType => {
  const shouldChartRender = chartdata?.length > 1
  const labels = getChartLabels(chartdata)
  const datasets = getChartDataSet(chartdata, options)
  const participants = chartdata.reduce((prev, cur) => cur.numresponses + prev, 0)
  const dividend = chartdata.length || 1
  const optionSums: OptionSum = {}
  chartdata.forEach(({ groups = [] }) =>
    groups.forEach(({ data, label }) => {
      const sum = optionSums[label] || 0
      optionSums[label] = sum + Number.parseInt(`${data}`)
    })
  )
  const maxSum = Math.max(...Object.values(optionSums))
  const results = options.map((label) => {
    const tmpSum = optionSums[label] || 0
    const winner = tmpSum === maxSum
    const data = Math.floor(tmpSum / dividend)
    return { label, data, winner }
  })
  const dataSource = { labels, datasets }
  return { participants, results, dataSource, shouldChartRender }
}

export const transformProfileTitle = (
  profile: Pick<ProfileType, 'titledisplay' | 'title_override' | 'company_name'>
) => {
  const { titledisplay, company_name, title_override } = profile
  return (
    title_override ||
    (titledisplay && company_name && `${titledisplay} at ${company_name}`) ||
    titledisplay ||
    company_name
  )
}

export const getChannelsFromGroupMembership = (
  groupsMembership: GroupMembershipType[],
  channels: ChannelType[]
): PublicGroupMembership[] => {
  const groupMembershipChannels = groupsMembership[0]?.channels ?? []

  return groupMembershipChannels.flatMap((channel) => {
    const profileChannel = channels?.find(({ slug }) => slug === channel.slug)
    if (!profileChannel) {
      return []
    }
    return { name: profileChannel.name, slug: profileChannel.slug }
  })
}

export const getProfilePicture = (url: string) => {
  if (url?.includes('peerProfileDefault')) {
    return ''
  }

  return url
}

export const transformToPublicProfile = ({
  titledisplay,
  gpiindustries,
  company_size,
  contribution_points,
  pulsepoints,
  pic: picParam,
  displayname,
  email,
  slug,
  country,
  social_media,
  is_ambassador: isAmbassador,
  firstname,
  _id: id,
  company_name: companyName,
  departments: departmentsProp,
  title_override,
  verification_state,
  is_readonly,
  access_form_required,
  use_split_access_form,
  consumption_access_form_required,
  contribution_access_form_required,
  has_demo_access,
  is_points_required,
  connections_count,
  first_seen: firstSeen,
  customer: isCustomer,
  level,
  primary_gpi_function: primaryFunction,
  groups_membership,
  channels,
  additional_gpi_functions,
  stats,
}: PartialProfileType): PublicProfileType => {
  const size = getCompanySizeLabel(company_size)
  const gpiInd = gpiindustries?.[0] || 0
  const industry = getIndustryLabel(gpiInd)
  const companySize = (size && `${size} employees`) || 'Self-employed'
  const isAnonymous = titledisplay === 'No Title - Anonymous'
  const isVerified = verification_state === UserStatus.USER_VERIFIED
  const isUserBlocked = verification_state === UserStatus.USER_BLOCKED
  const title = transformProfileTitle({
    title_override,
    company_name: companyName,
    titledisplay,
  })
  const pic = getProfilePicture(picParam)
  const departments = departmentsProp?.ids ?? []
  const isReadOnly = is_readonly ?? true
  const contributionPoints = contribution_points ?? 0
  const pulsePoints = pulsepoints ?? 0
  const hasDemoAccess = has_demo_access ?? false
  const connectionsCount = connections_count ?? 0
  const groupsMembership = getChannelsFromGroupMembership(groups_membership ?? [], channels ?? [])
  const hasPeerFinderAccess = false
  const isPointsRequired = is_points_required ?? true
  const accessFormRequired = access_form_required ?? false
  const useSplitAccessForm = use_split_access_form ?? false
  const consumptionAccessFormRequired = consumption_access_form_required ?? false
  const contributionAccessFormRequired = contribution_access_form_required ?? false
  const additionalGpiFunctions = additional_gpi_functions ?? []
  const publicStats = transformToPublicProfileStats(stats)

  const profile: PublicProfileType = {
    id,
    title,
    industry,
    companySize,
    companyName,
    isAnonymous,
    contributionPoints,
    pulsePoints,
    pic,
    displayname,
    email,
    slug,
    country,
    isAmbassador,
    isVerified,
    firstname,
    departments,
    isReadOnly,
    hasDemoAccess,
    connectionsCount,
    firstSeen,
    isCustomer,
    level,
    primaryFunction,
    additionalGpiFunctions,
    groupsMembership,
    hasPeerFinderAccess,
    isPointsRequired,
    accessFormRequired,
    useSplitAccessForm,
    consumptionAccessFormRequired,
    contributionAccessFormRequired,
    isUserBlocked,
    publicStats,
  }

  if (social_media && (social_media.twitter_url || social_media?.linkedin_url)) {
    profile.socialMedia = {
      twitterUrl: social_media.twitter_url,
      linkedInUrl: social_media.linkedin_url,
    }
  }

  return profile
}

const getFromNow = (time: ConfigType) => dayjs(time).fromNow() || 'a long time ago'

const transformToPublicReply = (
  reply: CommentType,
  profiles: Map<string, ProfileType>,
  index: number,
  post: PostType,
  commentIdx: number,
  mentions: Mention[]
): PublicReplyType => {
  const profile = profiles.get(reply.profileid) || EmptyProfile
  return {
    postId: post._id,
    postType: post.type,
    createdate: reply.createdate,
    fromNow: getFromNow(reply.createdate),
    text: reply.text,
    likes: reply.upvoters.length,
    profile: transformToPublicProfile(profile),
    commentIndex: commentIdx,
    replyIndex: index,
    upvoters: reply.upvoters,
    entityType: UpvoteTypes.REPLY,
    stats: {
      views: 0, // not tracked atm
      likes: reply.upvoters?.length || 0,
      totalComments: reply.replies?.length || 0,
    },
    mentions: mentions.filter(
      ({ location, createdTime, authorProfileId }) =>
        location === 'reply' && createdTime === reply.createdate && authorProfileId === reply.profileid
    ),
  }
}

const transformToPublicComment = (
  comment: CommentType,
  profiles: Map<string, ProfileType>,
  index: number,
  post: PostType,
  mentions: Mention[]
) => {
  const profile = profiles.get(comment.profileid) || EmptyProfile
  const publicComment: PublicCommentType = {
    createdate: comment.createdate,
    fromNow: getFromNow(comment.createdate),
    text: comment.text,
    likes: comment.upvoters.length,
    profile: transformToPublicProfile(profile),
    replies: comment.replies?.map((r, idx) => transformToPublicReply(r, profiles, idx, post, index, mentions)) || [],
    commentIndex: index,
    postId: post._id,
    postType: post.type,
    upvoters: comment.upvoters,
    entityType: UpvoteTypes.COMMENT,
    stats: {
      views: 0, // not tracked atm
      likes: comment.upvoters?.length || 0,
      totalComments: comment.replies?.length || 0,
    },
    mentions: mentions.filter(
      ({ location, createdTime, authorProfileId }) =>
        location === 'comment' && createdTime === comment.createdate && authorProfileId === comment.profileid
    ),
  }
  return publicComment
}

const getTimestampFromId = (id: string) => Number.parseInt(id.substring(0, 8), 16) * 1000

export const getMentionUrl = (kind: MentionKind, mention: MentionedObject): string => {
  const base = 'https://www.gartner.com/reviews'

  const { market_seo_name, vendor_seo_name, product_seo_name } = mention

  let withoutParams = ''

  if (kind === 'product' && market_seo_name && vendor_seo_name && product_seo_name) {
    withoutParams = `${base}/market/${market_seo_name}/vendor/${vendor_seo_name}/product/${product_seo_name}`
  }

  if (kind === 'vendor' && vendor_seo_name) {
    withoutParams = `${base}/vendor/${vendor_seo_name}`
  }

  if (kind === 'market' && market_seo_name) {
    withoutParams = `${base}/market/${market_seo_name}`
  }

  const params = new URLSearchParams({
    gxtm_source: 'peer-community',
    gxtm_page: 'detailview',
  })

  const url = new URL(withoutParams)
  url.search = params.toString()

  return url.toString()
}

export const getMentionKind = (mentionedObject: MentionedObject): MentionKind => {
  if (isProduct(mentionedObject)) return 'product'
  if (isVendor(mentionedObject)) return 'vendor'
  return 'market'
}

export const transformToPublicMention = (mentionedObject: MentionedObject): Mention => {
  const {
    author_profile_id,
    created_time,
    length,
    location,
    market_name,
    market_seo_name,
    start_index,
    target_text,
    logo_url,
    vendor_name,
    vendor_seo_name,
    product_name,
    product_seo_name,
  } = mentionedObject

  const kind = getMentionKind(mentionedObject)
  const url = getMentionUrl(kind, mentionedObject)

  let mention: Mention = {
    authorProfileId: author_profile_id,
    createdTime: created_time,
    length,
    location,
    marketName: market_name,
    marketSeoName: market_seo_name,
    startIndex: start_index,
    targetText: target_text,
    url,
    kind,
  }

  mention = addPropertyIfExists(mention, 'logoUrl', logo_url)
  mention = addPropertyIfExists(mention, 'vendorName', vendor_name)
  mention = addPropertyIfExists(mention, 'vendorSeoName', vendor_seo_name)
  mention = addPropertyIfExists(mention, 'productName', product_name)
  mention = addPropertyIfExists(mention, 'productSeoName', product_seo_name)

  return mention
}

/**
 * Transforms the mentioned objects (vendors, products, markets) into a flattened array of public mentions.
 *
 * @param {MentionedObjects | undefined} mentionedObjects - The object containing arrays of vendors, products, and markets to transform. If undefined or null, returns an empty array.
 * @returns {Mention[]} - A flattened array of transformed public mentions. If no objects are provided, returns an empty array.
 *
 * @example
 * const mentionedObjects = {
 *   vendors: [vendor],
 *   products: [product],
 *   markets: [market]
 * };
 * const result = combineMentions(mentionedObjects);
 * console.log(result); // [transformed vendor, transformed product, transformed market]
 *
 * @example
 * const result = combineMentions();
 * console.log(result); // []
 */
export const combineMentions = (mentionedObjects?: MentionedObjects): Mention[] => {
  if (!mentionedObjects) {
    return []
  }
  return Object.values(mentionedObjects).reduce<Mention[]>(
    (acc, current) => (current ? acc.concat(current.map(transformToPublicMention)) : acc),
    []
  )
}

function transformToPublicPostWithMappedProfiles(
  post: PostType,
  mappedProfiles: Map<string, ProfileType>,
  channel?: ChannelType,
  mentionedObjects?: MentionedObjects
): PublicPostType {
  const created = getTimestampFromId(post._id)
  const channelName = channelNames[post.channel_slug]
  const mentions = combineMentions(mentionedObjects)
  return {
    _id: post._id,
    postType: post.type,
    postId: post._id,
    created,
    fromNow: getFromNow(created),
    text: post.text,
    contentType: getLabel(post),
    stats: {
      views: post.views,
      likes: post.upvoters?.length || 0,
      totalComments: countCommentsAndReplies(post),
    },
    options: post.options,
    chartdata: post.chartdata,
    comments: post.comments?.map((x, idx) => transformToPublicComment(x, mappedProfiles, idx, post, mentions)) || [],
    tags: post.tag_ids?.map((id) => ({
      text: getTagName(id),
      slug: getTagSlug(id),
    })),
    num_responses: post.num_responses,
    source: post.source,
    type: post.type,
    slug: post.slug,
    path: getPostPath(post),
    pollSummary: transformToPublicPollDataSummary(post),
    isSeoIndexable: isSeoPage(post),
    isSurvey: !!post.parent,
    upvoters: post.upvoters,
    entityType: UpvoteTypes.POST,
    departments: post.departments,
    accessType: post.access_type,
    channel,
    channelName,
    isSaved: post.is_saved,
    isReadOnly: post.is_readonly,
    mentions: mentions.filter(({ location }) => location === 'root' || location === 'root-body'),
  }
}

export const transformToPublicPost = (
  post: PostType,
  profiles: ProfileType[],
  channel?: ChannelType,
  mentioned_objects?: MentionedObjects
): PublicPostType => {
  const mappedProfiles = mapProfiles(profiles)
  return transformToPublicPostWithMappedProfiles(post, mappedProfiles, channel, mentioned_objects)
}

export const transformToPublicRelatedPosts = (posts: PostType[], profiles: ProfileType[]): PublicPostType[] => {
  const mappedProfiles = mapProfiles(profiles)
  return posts
    .filter((p) => !isReportType(p.type))
    .map((post) => transformToPublicPostWithMappedProfiles(post, mappedProfiles))
}

export const transformToPublicProfileStats = (
  stats: StatsDTO = { written_answers_count: 0, upvotes_received_count: 0, profile_viewed_count: 0 }
): ProfileStatsType => ({
  answers: stats.written_answers_count,
  upvotes: stats.upvotes_received_count,
  views: stats.profile_viewed_count,
})

type AwardAccType = { [key: string]: ProfileAwardType }

export const transformToPublicProfileAwards = (awards: AwardDTO[] | null): ProfileAwardCount => {
  if (!awards) {
    return {
      total: 0,
      items: [],
    }
  }

  const total = awards.length

  const grouped = awards.reduce((acc: AwardAccType, curr: AwardDTO) => {
    const { tag_id, tag_name } = curr
    const existingTag = acc[tag_id]
    if (existingTag) {
      existingTag.count++
    } else {
      acc[tag_id] = { id: tag_id, name: tag_name, count: 1 }
    }
    return acc
  }, {} as AwardAccType)

  const sorted = Object.values(grouped).sort((a, b) => b.count - a.count)

  return { total, items: sorted }
}

export const transformToPublicProfileSkills = (skills: SkillDTO[] | null): ProfileSkillType[] => {
  if (!skills) {
    return []
  }
  return skills
    .filter(({ tag_id, expertise }) => {
      const tagExists = TagNames[tag_id] !== undefined
      const hasExpertise = expertise.declared || (!expertise.declared && expertise.level > 0)
      return tagExists && hasExpertise
    })
    .map(({ tag_id, expertise, interest }) => {
      const skill: ProfileSkillType = {
        id: tag_id,
        name: TagNames[tag_id],
        level: expertise.level,
        slug: TagSlugs[tag_id],
        selfDeclared: expertise.declared,
      }

      if (interest.declared) {
        skill.level++
      }

      return skill
    })
    .sort((a, b) => b.level - a.level)
}

type SortFn = PublicCommentSortFn
export const oldestFn: SortFn = (a, b) => new Date(a.createdate).getTime() - new Date(b.createdate).getTime()
export const newestFn: SortFn = (a, b) => oldestFn(b, a)
export const mostUpvotesFn: SortFn = (a, b) => b.likes - a.likes
export const mostRepliesFn: SortFn = (a, b) => b.replies.length - a.replies.length
export const commentSortFunctions: {
  [x in CommentSortBy]: PublicCommentSortFn
} = {
  [CommentSortBy.oldest]: oldestFn,
  [CommentSortBy.newest]: newestFn,
  [CommentSortBy.mostUpvoted]: mostUpvotesFn,
  [CommentSortBy.mostReplied]: mostRepliesFn,
} as const
