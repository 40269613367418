export const FeedTypes = {
  LongForm: 7,
  ProductPost: 200,
  Poll: 4,
  PollWithMultipleAnswers: 5,
  Text: 3,
  Rank: 6,
  Slider: 2,
  RatingScale: 1,
  MultiSelect: 5,
  AutoComplete: 8,
  Report: 100,
  ProductReport: 101,
  AssessmentReport: 102,
} as const

export const MAX_COMMENTS = 5

export const MAX_POLL_DATA = 12

export const MAX_TAGS = 3

export const MAX_REPLIES = 2

export const SHOW_CREATED_DATE = true

export const SHOW_COMMENTS_SORT_BY = false

export const POST_RELATED_MAX_COMMENTS = 1

export const MAX_COMMENT_LENGTH = 220

export const PAGE_INDEX_COMMENTS_THRESHOLD = 2

export const ASK_QUESTION_POINTS = 100

export const CREATE_POLL_POINTS = 300

export const MAX_DEPARTMENTS_IN_ALLOWED_CONTRIBUTORS_MESSAGE = 2

export const MAX_AMBASSADORS_PROFILES_PER_PAGE = 30

export const NEXT_AMBASSADORS_PREFIX = '/ambassadors'

export const SITEMAP_ROUTES = ['oneminuteinsights', 'discussions', 'ambassadors', 'post', 'poll']

export const CONTENT_STATE_UNVERIFIED = -4
export const CONTENT_STATE_VERIFIED = 0

export const MAX_SKILLS_IN_PROFILE = 5
export const VALUE_PATTERN = ':param_value'

export const ONE_DAY = 86400

export const ITEMS_PER_PAGE = 60

export const PULSE_GROUP = 'pulse'
