import { FeedTypes } from '@/constants/gpc'
import { GPC_DEPARTMENTS } from '@/constants/gpcDepartments'
import { CLOSED, CompanySizes, Industries, NO_LABEL, TypeLabels, TypePathPrefixByLabel } from '@/constants/postInfo'
import { TagNames, TagSlugs } from '@/constants/tagInfo'
import type { ContentType, PostType, PublicPostType, PublicProfileType } from '@/types/api'
import { hasSameValue } from './array'
import { isProfileReadOnly } from './profile'
import { isString } from './strings'

export const getLabelByType = (type: number): ContentType => TypeLabels[type] || NO_LABEL

export const isQuestion = (type: number): boolean => type === FeedTypes.LongForm

export const isPollType = (type: number): boolean =>
  [FeedTypes.Poll, FeedTypes.MultiSelect, FeedTypes.Rank, FeedTypes.RatingScale].some((pollType) => pollType === type)
export const isPollTypeByPost = (post: PublicPostType): boolean => isPollType(post.type)

export const isReportType = (type: number): boolean =>
  [FeedTypes.AssessmentReport, FeedTypes.ProductReport, FeedTypes.Report].some((feedType) => feedType === type)

export const getLabel = ({ locked, type }: PostType): ContentType => (locked ? CLOSED : getLabelByType(type))

export const countComments = ({ comments }: PostType): number => comments?.length || 0

export const countReplies = ({ comments }: PostType): number =>
  comments?.reduce((prev, cur) => prev + (cur.replies?.length || 0), 0) || 0

export const countCommentsAndReplies = (post: PostType): number => countComments(post) + countReplies(post)

export const getCompanySizeLabel = (size = 0) => CompanySizes[size] || ''

export const getIndustryLabel = (industry = 0) => Industries[industry] || ''

// TODO: get the tags from /api/v1/list-skills endpoint
export const getTagName = (tagId = '') => TagNames[tagId] || ''

export const getTagSlug = (tagId = '') => TagSlugs[tagId] || ''

export const getPostPath = ({ type, slug }: PostType) => {
  const label = getLabelByType(type)
  const prefix = TypePathPrefixByLabel[label] || '/topic'
  return `${prefix}/${slug}`
}

const TOTAL_DEPARTMENTS = 18 // Magic number coming from the SPA 🤷🏻‍♂️

export const getDepartmentNames = (
  departmentIds: PublicPostType['departments'],
  userDepartments: PublicPostType['departments'] = [],
  totalDepartments = TOTAL_DEPARTMENTS
): string[] => {
  if (!departmentIds?.length || departmentIds.length === totalDepartments) {
    return []
  }

  if (userDepartments) {
    departmentIds.sort((a, b) => Number(userDepartments.includes(b)) - Number(userDepartments.includes(a)))
  }

  return (
    departmentIds
      // There's a check of string vs number in the SPA, so apparently departments can sometimes be strings.
      // In the DB, at first sight departments are always numbers, but I'll lleave this here just in case
      // we are missing some edge case.
      .map((id) => (isString(id) ? id : GPC_DEPARTMENTS[id]?.name))
      .filter((name) => name !== undefined && name !== 'Other')
  )
}

export const isPostReadOnly = (post: PublicPostType, profile: PublicProfileType) => {
  const { isReadOnly } = post
  const postDepartments = post.departments ?? []
  const postHasDepartments = postDepartments.length > 0
  const shareDepartments = hasSameValue(profile.departments, postDepartments)
  return isReadOnly || (!shareDepartments && postHasDepartments)
}

export const canProfileInteractWithPost = (post: PublicPostType, profile: PublicProfileType) =>
  !isPostReadOnly(post, profile) && !isProfileReadOnly(profile)
